import {
  AstroBreadcrumb,
  breadcrumbArray,
} from '@/components/common/AstroBreadcrumb';
import HeadTitle from '@/components/common/HeadTitle';
import PageTitle from '@/components/common/PageTitle';
import Layout from '@/components/template/Layout';
import { useTranslation } from 'react-i18next';
import ProjectService from '../../components/projects/form/ProjectService';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@/utils/routes';

export default function ProjectFormPage() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'projects.view',
  });
  const [tFields] = useTranslation('translation', {
    keyPrefix: 'projects.fields',
  });
  const { state: projectState } = useLocation();

  const title = !projectState ? tFields('publish') : tFields('update');
  const navigate = useNavigate();
  const breadcrumbItems: breadcrumbArray[] = [
    {
      name: 'Home',
      routeType: 'home',
      url: '/',
    },
    { name: t('projects'), routeType: 'projects', url: '/projects' },
    {
      name: title,
      routeType: 'projects',
      url: '',
    },
  ];

  return (
    <Layout className="overflow-x-hidden">
      <HeadTitle routeInfo={`${title} - ${t('projects')}`} />
      <header className="flex flex-col gap-3">
        <AstroBreadcrumb breadcrumbList={breadcrumbItems} />
        <PageTitle position="mb-2" headingText={title} backButton />
      </header>
      <ProjectService
        project={projectState}
        onSuccess={project =>
          !projectState && navigate(ROUTES.PROJECTS.INFO(project.id))
        }
      />
    </Layout>
  );
}
