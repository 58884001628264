import MainButton from '@/components/common/buttons/MainButton';
import ComponentGuard from '@/components/common/ComponentGuard';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Text from '@/components/common/dataDisplay/Text';
import ErrorComponent from '@/components/common/ErrorComponent';
import InfinityList from '@/components/common/InfinityList';
import Card from '@/components/projects/cards/Card';
import ProjectCardsFactory from '@/components/projects/cards/factory/ProjectCardsFactory';
import CourseTypeFilter from '@/components/projects/carousel/filters/CourseTypeFilter';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { ProjectFilters } from '@/data/services/projectServices';
import { projectsQuerykeys } from '@/data/services/querykeys';
import { UserTypeEnum } from '@/models/User';
import { ChevronLeftIcon, CollectionIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import LoadingView from '../../courses/LoadingView';
import HackathonBanner from '../../../components/projects/hackathon/HackathonBanner/HackathonBanner';
import useHackathon, {
  useHackathonFilters,
} from '../../../components/projects/hackathon/HackathonGeneral.hooks';
import { spring } from '@/utils/animations/commom';

export default function ProjectHackathonPage() {
  const { filters: headerFilters } = useOutletContext<{
    filters: ProjectFilters;
  }>();

  const {
    hackathon,
    hackathonsStages,
    isLoading: isLoadingHackathon,
  } = useHackathon();
  const { t: tCarousel } = useTranslation('translation', {
    keyPrefix: 'projects.carousel',
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'projects.page',
  });
  const { t: tHackathon } = useTranslation('translation', {
    keyPrefix: 'hackathon',
  });
  const [sectionFilters, setSectionFilters] = useState({});

  const hackathonFilters = useHackathonFilters();

  const allFilters = {
    ...headerFilters,
    ...sectionFilters,
    ...hackathonFilters,
  };

  const {
    results: projects,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
    isFetching,
    isLoading,
  } = useInfiniteService({
    ...projectsQuerykeys.list(allFilters)._ctx.infinity,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const loadingCards = Array.from({ length: 20 }).map((_, index) => (
    <Card.Skeleton key={index} className="w-full" />
  ));
  const navigate = useNavigate();

  if (isLoadingHackathon) {
    return <LoadingView />;
  }

  if (!hackathon && !isLoadingHackathon) {
    return (
      <ErrorComponent
        statusCode={404}
        errorTextTitle={t('hackathon')}
        errorTextSubTitle={t('noHackathon')}
      />
    );
  }

  if (hackathon) {
    return (
      <div className="flex flex-col gap-4">
        <div className="w-full pt-5 pb-3 sticky top-0 bg-base-200 z-10">
          <div className="relative flex justify-between w-full gap-2.5  items-center">
            <Text
              format="rubik-500"
              size="text-18"
              text={`#${t('hackathon')}${hackathon.edition}`}
              className="text-primary"
            />
            <MainButton
              text={tCarousel('back')}
              onClick={() => {
                navigate('..');
              }}
              color="custom"
              className={twJoin('font-600 !text-14 text-primary gap-1')}
              icon={
                <ChevronLeftIcon className="w-4 h-4 ease-in-out transition-all duration-200" />
              }
            />
          </div>

          <div className="absolute left-full top-0 w-5 h-full bg-base-200"></div>
        </div>
        <HackathonBanner
          hackathon={hackathon}
          hackathonStages={hackathonsStages}
        />
        <div className="flex justify-between w-full items-center">
          <CourseTypeFilter
            size="medium"
            filter={sectionFilters}
            onFilter={setSectionFilters}
          />
          <ComponentGuard roles={[UserTypeEnum.STUDENT]}>
            <ConditionalRenderer condition={hackathonFilters.shunfle}>
              <MainButton
                text={tHackathon('shunfle')}
                loading={isFetching}
                color="custom"
                onClick={() => refetch()}
                className="font-600 !text-14 text-primary gap-1 flex-row-reverse"
                icon={
                  <CollectionIcon
                    className={
                      'w-4 h-4 ease-in-out transition-all duration-200'
                    }
                  />
                }
              />
            </ConditionalRenderer>
          </ComponentGuard>
        </div>
        <ConditionalRenderer condition={!projects.length && !isLoading}>
          <div className="flex w-full justify-center items-center h-72">
            <Text text={t('noProjectsFound')} />
          </div>
        </ConditionalRenderer>
        <InfinityList
          hasNextPage={hasNextPage}
          onReachEnd={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2.5"
        >
          <ConditionalRenderer condition={!isLoading} fallback={loadingCards}>
            {projects.map(project => (
              <motion.div
                key={project.id}
                layout
                transition={spring}
                className="flex-grow min-w-[300px] w-full"
              >
                <ProjectCardsFactory
                  project={project}
                  filters={allFilters}
                  key={project.id}
                  mode="general"
                  className={{
                    base: 'w-full min-w-full xl:min-w-full',
                  }}
                />
              </motion.div>
            ))}
          </ConditionalRenderer>
        </InfinityList>
      </div>
    );
  }
  return <Fragment />;
}
